import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { v4 as uuidv4 } from 'uuid'
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// import  "./styles/components/alert.scss"
import getSymbolFromCurrency from "currency-symbol-map";
import { getMetadata, getThumbnails } from "video-metadata-thumbnails";
import countryList from "react-select-country-list";
import { store } from "./index";
import { signOut } from "./store/actions/auth";
import { history } from "./router/AppRouter";

const mime = require('mime-types')

//Converts base64 dataurl to blob
const base64ToBlob = (base64DataUrl) => {
  //Split the base64DataUrl to extract the base64 and the contentType
  const base64PictureOnly = base64DataUrl.split(",", 2)[1];
  const contentType = base64DataUrl
    .split(",", 2)[0]
    .split(";")[0]
    .split(":")[1];

  //convert the base64 to byteCharacters
  const byteCharacters = atob(base64PictureOnly);

  //Convert the byteCharacters to byteNumbers
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  //Convert the byte numbers to unit 8 array
  const byteArray = new Uint8Array(byteNumbers);

  //Convert the unit 8 array to blob
  const blob = new Blob([byteArray], { type: contentType });
  return blob;
};

//Crops an image
const getCroppedImage = async (base64Url, pixelCrop, rotation = 0) => {

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  /**
   * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
   * @param {File} image - Image File url
   * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
   * @param {number} rotation - optional rotation parameter
   */

  // const image = await createImage(imageSrc)
  const image = new Image();
  image.src = base64Url;

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
  );

  // As Base64 string
  return canvas.toDataURL("image/jpeg");

  // As a blob
  // return new Promise(resolve => {
  //     canvas.toBlob(file => {
  //         resolve(URL.createObjectURL(file))
  //     }, 'image/jpeg')
  // })
};

const getConfirmAlert = ({ title, message, onClickYes, onClickNo, variant }) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="alert-container">
          <div className="alert-container__content">
            <div className="d-flex justify-content-between">
              <h1 className={`alert-title ${variant == 'danger' ? 'red' : ''}  ${variant == 'success' ? 'normal-green' : ''}`}>{title}</h1>
              <i
                className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
                onClick={() => {
                  onClickNo();
                  onClose();
                }}
              ></i>
            </div>
            <p className={`alert-message ${variant == 'danger' ? 'red' : ''}`}>{message}</p>
            <div className="d-flex">
              <button
                className={`alert-confirm-btn 
                ${variant == 'danger' ? 'bg-red' : ''}  ${variant == 'success' ? 'bg-normal-green' : ''}`}
                onClick={() => {
                  onClickYes();
                  onClose();
                }}
              >
                CONFIRM
              </button>
              <button
                className="alert-cancel-btn hover-red"
                onClick={() => {
                  onClickNo();
                  onClose();
                }}
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const getAcceptOrReject = ({
  title,
  message,
  onClickAccept,
  onClickReject,
}) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="alert-container custom-react-alert">
          <div className="alert-container__content">
            <h1 className="alert-title">{title}</h1>
            <p className="alert-message">{message}</p>
            <button
              className="alert-no-btn max-width-90"
              onClick={() => {
                onClickReject();
                onClose();
              }}
            >
              Reject
            </button>
            <button
              className="alert-yes-btn max-width-90"
              onClick={() => {
                onClickAccept();
                onClose();
              }}
            >
              Accept
            </button>
          </div>
        </div>
      );
    },
  });
};

const getCurrencySymbol = (currency) => {
  const dollarCurrencies = ["USD", "CAD", "LRD", "ZWD"]
  let prefix = ""

  if (dollarCurrencies.includes(currency)) {
    prefix = currency.substring(0, 2)
  }

  return getSymbolFromCurrency(currency)
    ? prefix + getSymbolFromCurrency(currency)
    : currency;
};

const generateVideoThumbnail = async (blob) => {
  const thumbnails = await getThumbnails(blob, {
    interval: 1,
    start: 0,
    end: 0,
  });

  return thumbnails[0];
};

const generateImageThumbnail = async (base64Url, width) => {
  const createImage = (url) =>
    new Promise((resolve, reject) => {
      var img = new Image();

      img.src = base64Url;

      img.onload = function () {
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        canvas.width = width;
        canvas.height = canvas.width * (img.height / img.width);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // SEND THIS DATA TO WHEREVER YOU NEED IT
        var data = canvas.toDataURL("image/png");
        // return data

        resolve(data);
      };
    });

  const image = await createImage(base64Url);

  return image;
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const truncateLongText = (string, maxCharactersNo) => {
  return string.substring(0, maxCharactersNo);
};

const getEUCountryCodes = () => {
  return ["BE", "BG", "CZ", "DK", "DE", "EE", "IE", "EL", "ES", "FR", "HR", "IT", "CY", "LV", "LT", "LU", "HU", "MT", "NL", "AT", "PL",
    "PT", "RO", "SI", "SK", "FI", "SE", "IS", "LI", "NO", "CH"]
}

const setIsCookieConsentNeeded = (dispatch, locale) => {
  // Set if the client needs to give consent
  const EUCountryCodes = getEUCountryCodes()
  let client_country_code = locale.country_code
  if (EUCountryCodes.includes(client_country_code)) {
    dispatch({
      type: "SET_IS_COOKIE_CONSENT_NEEDED",
      isCookieConsentNeeded: true
    })
  } else {
    dispatch({
      type: "SET_IS_COOKIE_CONSENT_NEEDED",
      isCookieConsentNeeded: false
    })
  }
}

const convertSnakeToStandardCasing = (value) => {
  if (value) {
    let splits = value.split("_")
    splits = splits.map(e => {
      return e.toLowerCase().substring(0, 1).toUpperCase() + e.toLowerCase().substring(1)
    })
    splits = splits.join(" ")
    return splits
  } else {
    return value
  }
}

const getDocumentFileformatFromUrl = (fileUrl) => {
  if (/pdf/i.test(mime.lookup(fileUrl))) {
    return "pdf";
  } else if (/image/i.test(mime.lookup(fileUrl))) {
    return "image";
  } else if (/video/i.test(mime.lookup(fileUrl))) {
    return "video";
  } else if (/audio/i.test(mime.lookup(fileUrl))) {
    return "audio";
  } else {
    return null
  }
};


const getFileNameFromFileObj = (file_url) => {
  return file_url.split("/")[5];
};

const getFileFormatFromMimeType = (mimeType) => {
  if (/pdf/i.test(mimeType)) {
    return "pdf";
  } else if (/image/i.test(mimeType)) {
    return "image";
  } else if (/video/i.test(mimeType)) {
    return "video";
  } else if (/audio/i.test(mimeType)) {
    return "audio";
  } else {
    return null
  }
}

const readFileAsBase64DataURL = async (file, callBack) => {
  let base64File = ""
  if (file) {
    const fileReader = new FileReader();
    fileReader.onload = async (fileLoadedEvent) => {
      base64File = fileLoadedEvent.target.result;
      await callBack(base64File)
    };
    fileReader.readAsDataURL(file);
  }
}

const getBase64onlyString = (base64) => {
  return base64.replace(/^data:image\/[a-z]+;base64,/, "")
}

const convertCountryCodeToName = (countryCode) => {
  let countryName
  if (countryList().getLabel(countryCode)) {
    countryName = countryList().getLabel(countryCode)
  } else {
    countryName = countryCode
  }
  return countryName
}

const storeAuthCredentials = (data) => {
  let token = uuidv4()

  if (data.environment == "Development Test") {
    token = data.token
  }

  localStorage.setItem("token", token);
  localStorage.setItem("expiry", data.expiry);
  localStorage.setItem("user", JSON.stringify(data.user));
  if (data.user.authentication_method) {
    localStorage.setItem("authenticationMethod", data.user.authentication_method);
  }

  store.dispatch({ type: "RESTORE_TOKEN", token, user: data.user })
}

const clearAuthCredentials = async () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expiry");
  localStorage.removeItem("user");
  localStorage.removeItem("isNabbing")
  localStorage.removeItem("authenticationMethod")

  store.dispatch({
    type: "SIGN_OUT",
    userToken: null,
    isLoading: false,
    expiry: null,
    user: {}
  });
}

const setTokenExpiryTimeout = () => {
  let expiry = localStorage.getItem("expiry");

  const timeLeftInMs = new Date(expiry).getTime() - new Date().getTime()

  setTimeout(() => {
    // Sign out 1 second before token expiry
    signOut()
  }, timeLeftInMs - 1000)

  setTimeout(() => {
    clearAuthCredentials()
  }, timeLeftInMs)
}

const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    let cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].toString().replace(/^([\s]*)|([\s]*)$/g, "")
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const convertObjectToArray = (object) => {
  //Receives object = {a: b, c: d}
  //Produces Array = [{a: b}, {c:d}]
  const newArray = Object.entries(object).map(subArr => {
    const newobj = {}
    newobj[subArr[0]] = subArr[1]
    return newobj
  })
  return newArray
}


// To refresh the page on delay
const refreshPage = (delay) => {
  if (delay) {
    setTimeout(() => {
      history.go()
    }, delay)
  } else {
    history.go()
  }
}

// To format money by adding thousand commas 
const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const fetchWalletBalances = (user) => {
  let balanceRef = new WebSocket(
    `${process.env.REACT_APP_SOCKET_BASE_URL}/ws/pouch_wallet/${user.id}/connect`)

  balanceRef.onopen = e => {
    balanceRef.send(JSON.stringify({
      type: "wallet_balances",
      user_id: JSON.parse(process.env.REACT_APP_LOCAL_TO_REMOTE_SERVER) ? user.id : null
    }))
  }

  balanceRef.onmessage = e => {
    const data = JSON.parse(e.data)

    if (data.type == "wallet_balances") {
      store.dispatch({ type: "STORE_WALLET_BALANCES", walletBalances: data.balances })
    }
  }
}

const isEnoughMoneyInWallet = (amount, currency) => {
  let isEnough = false
  const balances = store.getState().auth.walletBalances
  const relevantBalance = balances.find(item => item.currency == currency)
  if (relevantBalance) {
    if (relevantBalance.available_balance >= amount) {
      isEnough = true
    }
  }

  return isEnough
}

// Distinct colors
const getDistinctColor = (number) => {
  const hue = number * 137.508; // use golden angle approximation
  return hue
}

// Show no service notification
const show_no_service_notification = (email) => {
  const admins = ["efosaakenbor@yahoo.com", "uwailaakenbor@yahoo.com", "odiakenbor@yahoo.com"]

  if (!admins.includes(email) &&
    process.env.NODE_ENV == "production" &&
    process.env.REACT_APP_LOCAL_TO_REMOTE_SERVER == false) {
    return true
  } else {
    return false
  }
}

export {
  base64ToBlob,
  getCroppedImage,
  getConfirmAlert,
  getAcceptOrReject,
  getCurrencySymbol,
  generateVideoThumbnail,
  generateImageThumbnail,
  capitalizeFirstLetter,
  truncateLongText,
  getEUCountryCodes,
  setIsCookieConsentNeeded,
  convertSnakeToStandardCasing,
  getDocumentFileformatFromUrl,
  getFileNameFromFileObj,
  getFileFormatFromMimeType,
  readFileAsBase64DataURL,
  getBase64onlyString,
  convertCountryCodeToName,
  storeAuthCredentials,
  clearAuthCredentials,
  getCookie,
  setTokenExpiryTimeout,
  convertObjectToArray,
  refreshPage,
  numberWithCommas,
  fetchWalletBalances,
  isEnoughMoneyInWallet,
  getDistinctColor,
  show_no_service_notification
};
