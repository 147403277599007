import { STORE_USER } from "../actionTypes/auth";
import { history } from "../../router/AppRouter";
import { generateTransactionRef } from "../actions/transaction"
import { storeAuthCredentials, clearAuthCredentials, setTokenExpiryTimeout } from "../../utilities"
import axiosInstance from "../../helpers/axiosInstance";

// Get the client's locale
export const getClientLocale = () => {
  return async () => {
    return axiosInstance.get('/user/get-visitor-locale')
      .then(async res => {
        localStorage.setItem("locale", JSON.stringify(res.data.data));

        const locale = res.data.data
        return locale

      })
      .catch(async (error) => {
        return { error: error ? error.response.data : null }
      });
  }
};

// Get the list of currencies
export const getCurrencyList = () => {
  return async () => {
    return axiosInstance.get('/user/get-supported-currencies')
      .then(async res => {
        return res.data;
      })
      .catch(async (error) => {
        return { error: error ? error.response.data : null }
      });
  }
};

// Register a new user
export const register = (values) => {
  return async () => {
    return axiosInstance.post('/rest-auth/registration/', {
      email: values.email,
      password1: values.password,
      password2: values.repeatPassword,
      first_name: values.firstName,
      last_name: values.lastName,
      company_name: values.companyName,
      phone_number: values.phoneNumber,
      country: values.country,
      preferred_currency: values.preferredCurrency,
      referrer_code: values.referrer_code
    })
      .then(() => {
        localStorage.removeItem("referrerCode")

        return { message: "Verification email sent" }

      })
      .catch(error => {
        let message

        if (error && error.response && error.response.data) {
          if (error.response.data.password1) {
            message = error.response.data.password1[0]
          } else if (error.response.data.email) {
            message = error.response.data.email[0]
          }
        }

        return { error: message }
      })
  }
};

// Request password reset email
export const requestPasswordResetEmail = (values) => {
  return () => {
    return axiosInstance.post("/user/request-password-reset", {
      email: values.email,
    })
      .then((res) => {

        return res.data

      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Sign in user
export const signIn = (data) => {
  return async (dispatch) => {
    return axiosInstance.post('/api/auth/login/', data)
      .then(async response => {
        storeAuthCredentials(response.data)

        setTokenExpiryTimeout()

        dispatch(generateTransactionRef())

        localStorage.removeItem("referrerCode")

        return { message: "OK" }

      })
      .catch(error => {
        let loginError
        let isErrorUnverifiedEmail = false
        let mfaRequired
        let invalidOtp

        if (error.response) {
          if (error.response.data.non_field_errors) {
            // setLoginError();
            loginError = error.response.data.non_field_errors[0]
            if (
              loginError == 'E-mail is not verified.'
            ) {
              isErrorUnverifiedEmail = true
            }
          } else if (error.response.data.email) {
            loginError = error.response.data.email[0]
          } else if (error.response.data.message == "mfa is required") {
            mfaRequired = error.response.data
          } else if (error.response.data.message == "Invalid OTP") {
            invalidOtp = error.response.data
          } else if (error.response.data.detail) {
            loginError = error.response.data.detail

            if (loginError == 'This email is not verified') {
              isErrorUnverifiedEmail = true
            }
          }
        }

        return { error: { loginError, isErrorUnverifiedEmail, mfaRequired, invalidOtp } }
      })
  }
};

// Sign in by social media
export const socialMediaSignIn = (values) => {
  let queryString
  if (values.otp_type) {
    queryString = "otp_type=" + values.otp_type
  }

  if (values.otp) {
    const otpQueryString = "otp=" + values.otp
    if (queryString) {
      queryString = queryString + "&" + otpQueryString
    } else {
      queryString = otpQueryString
    }
  }

  if (values.referrer_code) {
    const otpQueryString = "referrer_code=" + values.referrer_code
    if (queryString) {
      queryString = queryString + "&" + otpQueryString
    } else {
      queryString = otpQueryString
    }
  }

  if (values.socialMediaType) {
    const socialMediaTypeString = "social_media_type=" + values.socialMediaType
    if (queryString) {
      queryString = queryString + "&" + socialMediaTypeString
    } else {
      queryString = socialMediaTypeString
    }
  }

  if (values.firstName) {
    const firstNameString = "first_name=" + values.firstName
    if (queryString) {
      queryString = queryString + "&" + firstNameString
    } else {
      queryString = firstNameString
    }
  }

  if (values.lastName) {
    const lastNameString = "last_name=" + values.lastName
    if (queryString) {
      queryString = queryString + "&" + lastNameString
    } else {
      queryString = lastNameString
    }
  }

  let url
  if (values.socialMediaType == "facebook") {
    url = "/social-rest-auth/login/facebook/"
  } else if (values.socialMediaType == "google") {
    url = "/social-rest-auth/login/google/"
  } else if (values.socialMediaType == "apple") {
    url = "/social-rest-auth/login/apple/"
  }

  if (queryString) {
    url = url + "?" + queryString
  }

  return async (dispatch) => {
    return axiosInstance.post(url, {
      ...values,
      params: {
        otp_type: values.otp_type ? values.otp_type : ""
      }
    })
      .then(async response => {
        storeAuthCredentials(response.data)

        setTokenExpiryTimeout()

        dispatch(generateTransactionRef())

        localStorage.removeItem("referrerCode")

        return { message: "OK" }

      })
      .catch(error => {

        let loginError
        let mfaRequired
        let invalidOtp

        if (error.response.data.email) {
          loginError = error.response.data.email[0]
        } else if (error.response.data.message == "mfa is required") {
          mfaRequired = error.response.data
        } else if (error.response.data.message == "Invalid OTP") {
          invalidOtp = error.response.data
        }

        return { error: { loginError, mfaRequired, invalidOtp } }
      })
  }
};

// Update user
export const updateUser = (values) => {
  return (dispatch) => {
    return axiosInstance.patch('/user/update-user', values)
      .then(res => {
        dispatch(getUser())
        return res.data
      })
      .catch(error => {
        return { error: error.response.data }
      })
  }
};

// Resend verification email
export const requestVerificationEmail = (value) => {
  return () => {
    return axiosInstance.post('/user/resend-verification-email', value)
      .then(res => {
        return res.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};


// Change password
export const saveNewPassword = (data) => {
  return () => {
    return axiosInstance.post('/user/change-password', data)
      .then(res => {
        return { okMessage: res.data.detail }
      })
      .catch(error => {
        let loginError
        let mfaRequired
        let invalidOtp

        if (error.response.data) {
          if (error.response.data.detail) {
            loginError = error.response.data.detail
          } else if (error.response.data.message == "mfa is required") {
            mfaRequired = error.response.data
          } else if (error.response.data.message == "Invalid OTP") {
            invalidOtp = error.response.data
          }
        }

        return { error: { loginError, mfaRequired, invalidOtp } }
      })
  }
};

// SIGN_OUT
export const signOut = () => {
  return (dispatch) => {
    return axiosInstance.post("/rest-auth/logout/")
      .then(async (res) => {
        return res.data.detail;
      })
      .then(() => {
        clearAuthCredentials()

        dispatch({
          type: "STORE_HIJACK_STATUS",
          isNabbing: false
        })

        history.push("/login");
      })
      .catch(async () => {
        clearAuthCredentials()

        dispatch({
          type: "STORE_HIJACK_STATUS",
          isNabbing: false
        })
        history.push("/login");
      });
  };
};

// Reset password
export const resetPassword = (data) => {
  return () => {
    return axiosInstance
      .post("/user/password/reset/confirm", data)
      .then((res) => {
        history.push("/login");
        return res.data;
      })
      .catch(async (error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// Get User
export const getUser = () => {
  return (dispatch) => {
    return axiosInstance
      .get("/rest-auth/user/")
      .then((res) => {
        const user = res.data

        localStorage.setItem("user", JSON.stringify(res.data));

        dispatch({
          type: STORE_USER,
          user,
        });
        return user
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//Send phone verification otp
export const sendPhoneOtp = () => {
  return async () => {
    return axiosInstance.post(`/user/send-phone-verification-code`)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

//To verify mobile phone otp
export const verifyPhoneOtp = (data) => {
  return async () => {
    return axiosInstance.post(`/user/verify-phone-number`, data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
}

// Upload Profile Picture
export const uploadProfilePicture = (profilePicture) => {
  return async () => {
    return axiosInstance.post('/user/upload-profile-picture', profilePicture)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Delete Profile Picture
export const deleteProfilePicture = () => {
  return async () => {
    return axiosInstance.delete(`/user/delete-profile-picture`)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Upload a proof of id document
export const uploadIdProofDoc = (data) => {
  return async () => {
    return axiosInstance.post('/user/upload-identity-proof', data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Delete a proof of id document
export const deleteIdProofDoc = (data) => {
  return async () => {
    return axiosInstance.delete(`/user/delete-identity-proof-document/${data.id}`, data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Upload a proof of address document
export const uploadAddressProofDoc = (data) => {
  return async () => {
    return axiosInstance.post('/user/upload-address-proof', data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Delete a proof of address document
export const deleteAddressProofDoc = (data) => {
  return async () => {
    return axiosInstance.delete(`/user/delete-address-proof-document/${data.id}`, data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Subscribe to newsletter when signed in
export const subscribeNewsletter = (data) => {
  return async () => {
    return axiosInstance.post("/newsletter/subscribe", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Unsubscribe from newsletter when signed in
export const confirmNewsletterUnsubscribe = (params) => {
  return async () => {
    return axiosInstance.post("/newsletter/unsubscribe", {
      params
    })
      .then(() => {
        return
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Update a notification medium
export const editNotificationMedium = (data) => {
  return async () => {
    return axiosInstance.patch("/user/edit-notification-medium", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Enable phone 2-factor authentication
export const enableMultiFactorAuthMethod = (data) => {
  return async () => {
    return axiosInstance.post("/user/enable-multi-factor-authentication-method", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Generate authentication otp
export const generatePhoneAuthenticationOtp = () => {
  return async () => {
    return axiosInstance.post("/user/generate-phone-authentication-otp")
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Generate a phone otp before attempting login
export const sendPhoneOtpPreLogin = (data) => {
  return async () => {
    return axiosInstance.post("/user/send-phone-otp-pre-login", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Gwt authenticator app uri
export const getAuthenticatorAppUri = () => {
  return async () => {
    return axiosInstance.post("/user/get-authenticator-app-uri")
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Generate a new authenticator app uri when authenticated
export const generateNewAuthenticatorAppUriAuthenticated = () => {
  return async () => {
    return axiosInstance.post("/user/generate-new-authenticator-app-uri-authenticated")
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Generate a new authenticator app uri when NOT authenticated
export const getAuthenticatorAppUriWhenNotAuthenticated = (data) => {
  return async () => {
    return axiosInstance.post("/user/get-authenticator-uri-when-not-authenticated", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Send an authentication otp to email
export const sendEmailAuthenticationOtp = (data) => {
  return async () => {
    return axiosInstance.post("/user/send-email-authentication-otp", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Disable a 2-FA
export const disableMfa = (data) => {
  return async () => {
    return axiosInstance.patch("/user/disable-multi-factor-authentication-method", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Submit security answers
export const submitSecurityAnswers = (data) => {
  return async () => {
    return axiosInstance.post("/user/submit-security-answers", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Verify security answer while authenticated
export const verifySecurityAnswerAuthenticated = (data) => {
  return async () => {
    return axiosInstance.post("/user/verify-security-answer-authenticated", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Verify security answer by providing email and password
export const verifySecurityAnswerWithEmailPassword = (data) => {
  return async () => {
    return axiosInstance.post("/user/verify-security-answer-with-email-password", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Retrieve user security questions
export const retrieveUserSecurityQuestions = (data) => {
  return async () => {
    return axiosInstance.post("/user/retrieve-user-security-questions", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// For a user or a guest to submit contact form
export const submitContactForm = (data) => {
  return async () => {
    return axiosInstance.post("/user/submit-contact-form", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// For a user to submit their registered company
export const submitCompany = (data) => {
  return async () => {
    return axiosInstance.post("/user/submit-company-info", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// For a user to delete their registered company
export const deleteCompany = (data) => {
  return async () => {
    return axiosInstance.delete("/user/delete-my-company/" + data.id)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// For a logged in user to get the basic details of someone registered
export const getSomeoneBasicDetails = (email) => {
  return async () => {
    return axiosInstance.get("/user/get-someone-basic-details?email=" + email)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// For a logged in user to request for their account to be deleted
export const requestUserAccountDeletion = () => {
  return async (dispatch) => {
    return axiosInstance.post("/user/register-delete-user-account-request")
      .then(response => {
        const user = response.data.data
        dispatch({
          type: STORE_USER,
          user,
        });
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// For a logged in user to reverse the deletion of their account]
export const reverseUserAccountDeletion = () => {
  return async (dispatch) => {
    return axiosInstance.post("/user/reverse-user-account-deletion")
      .then(response => {
        const user = response.data.data
        dispatch({
          type: STORE_USER,
          user,
        });
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};



