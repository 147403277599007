import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { generateTransactionRef } from "../../../store/actions/transaction";
import { creditPouchWallet, initiatePayment, verifyPaymentStatus } from "../../../store/actions/pouchWallet";
import { getCurrencySymbol, numberWithCommas, refreshPage } from "../../../utilities";
import MoneyInputField from "../../utilityComponents/MoneyInputField"
import { NotificationManager } from "react-notifications";
import FormattedMoney from "../../utilityComponents/FormattedMoney";
import moment from "moment";
import PouchMoneyPaymentDetails from "../../payments/PouchMoneyPaymentDetails";

const TopUpWallet = ({
  history,
  user,
  transaction_ref,
  setIsPageLoading,
  dispatch,
  handleClose,
}) => {
  const [isPaymentDetailsVisible, setIsPaymentDetailsVisible] = useState(false);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    // Generate a transaction ref
    dispatch(generateTransactionRef());

    // Reformat the currencies structure
    const currencies = user.supported_currencies.map((currency) => {
      return {
        label: `${currency}`,
        value: currency,
      };
    });

    setCurrencies(currencies);
  }, []);

  return (
    <div>
      <Formik
        initialValues={{
          amount: "",
          currency: "NGN",
          email: user.email,
        }}
        validationSchema={Yup.object({
          amount: Yup.number()
            .typeError("Amount must be a number")
            .positive("Amount must be a positive number")
            .integer("Amount must be a whole number")
            .required("Amount is required")
            .nullable(),
          currency: Yup.string().required("Currency is required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setIsPaymentDetailsVisible(true)
        }}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
        }) => (
          <Form>
            {
              isPaymentDetailsVisible ?
                <div>
                  <i className="fa fa-arrow-left font-size-24 cursor-pointer" onClick={() => { setIsPaymentDetailsVisible(false) }}></i>
                  <PouchMoneyPaymentDetails amount={values.amount} bankDetails={user.bank_payment_details} />
                </div>
                :
                <div>

                  <div className="padding-top-12 padding-bottom-12">
                    <label className="form-label font-size-15">Amount</label>
                    <div>
                      <MoneyInputField
                        className="form-control padding-10 border-color-dark-blue fixed-height-40"
                        currency="NGN"
                        onChange={(amount) => {
                          setFieldValue("amount", amount)
                        }}
                        value={values.amount}
                        placeholder="Enter amount"
                      />
                    </div>
                    <ErrorMessage
                      className="error-message"
                      name="amount"
                      component="div"
                    />
                    {values.currency == "NGN" &&
                      values.amount >= 10000 &&
                      <span className="red">Note: Your wallet will be credited with {getCurrencySymbol(values.currency)}{numberWithCommas(values.amount - 50)} due to a ₦50 stamp duty.</span>
                    }
                  </div>

                  <div className="d-flex justify-content-end">
                    <button
                      className="btn margin-top-24 margin-bottom-20 padding-12 bg-dark-blue white max-width-250 font-size-17 border-radius-8"
                      type="button"
                      onClick={handleSubmit}
                    >
                      <b>Proceed</b>
                    </button>
                  </div>
                </div>
            }
          </Form>
        )}
      </Formik>


      {/* <Modal
        ariaHideApp={false}
        isOpen={isPaymentDetailsVisible}
        onRequestClose={() => setIsPaymentDetailsVisible(false)}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue">
            Payment Details
          </h1>
          <i
            onClick={() => setIsPaymentDetailsVisible(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line" />

        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-sm-20 padding-bottom-sm-30 padding-left-md-40 padding-right-md-40"
        >
          <PouchMoneyPaymentDetails bankDetails={user.bank_payment_details} />
        </div>
      </Modal> */}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    transaction_ref: state.transaction.transaction_ref,
  };
};

export default connect(mapStateToProps)(TopUpWallet);
